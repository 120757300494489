<template>
<div class="home">
  <Header/>
  <div class="updates" v-if="!isEn">
    <h2 class="updates-title">Updates</h2>
    <p class="updates-sub-title">Check out what's new at Little Star</p>
    <div class="updates-list">
      <section class="updates-section">
        <div class="updates-section-title">LitteStar 1.0</div>
        <div class="updates-section-time i18n" name="up_4_date">2021.07.01</div>
        <div class="updates-section-des">
          <ul class="updates-section-des-list">
            <li class="i18n" name="up_4_1">新版首页</li>
            <li class="i18n" name="up_4_2">首页添加Github Trending</li>
            <li class="i18n" name="up_4_3">新价格页面</li>
            <li class="i18n" name="up_4_3">改变Token存储策略<a target="_blank" class="link"   href="https://github.com/alianrock/LittleStar/wiki/Access-token-for-Little-Star">详细</a></li>
            <li class="i18n" name="up_4_4">Bug fix</li>
          </ul>
        </div>
      </section>
      <section class="updates-section updates-section_r">
        <div class="updates-section-title">LitteStar Beta 3.11</div>
        <div class="updates-section-time i18n" name="up_5_date">2021.01.22</div>
        <div class="updates-section-des">
          <ul class="updates-section-des-list">
            <li class="i18n" name="up_5_1">添加Github Trending</li>
            <li class="i18n" name="up_5_2">Star 数据同步更新bug</li>
          </ul>
        </div>
      </section>
      <section class="updates-section">
        <div class="updates-section-title">LitteStar Beta 3.0</div>
        <div class="updates-section-time i18n" name="up_4_date">2020.10.20</div>
        <div class="updates-section-des">
          <ul class="updates-section-des-list">
            <li class="i18n" name="up_4_1">独立大屏页面</li>
            <li class="i18n" name="up_4_2">数据同步速度优化</li>
            <li class="i18n" name="up_4_3">Email 设置</li>
            <li class="i18n" name="up_4_4">反馈界面</li>
          </ul>
        </div>
      </section>
      <section class="updates-section updates-section_r">
        <div class="updates-section-title">LitteStar Beta 2.0</div>
        <div class="updates-section-time i18n" name="up_3_date">2020.06.01</div>
        <div class="updates-section-des">
          <ul class="updates-section-des-list">
            <li class="i18n" name="up_3_1">添加黑暗模式</li>
            <li class="i18n" name="up_3_2">设置界面</li>
            <li class="i18n" name="up_3_3">自动备份</li>
            <li class="i18n" name="up_3_4">大小模式</li>
          </ul>
        </div>
      </section>
      <section class="updates-section">
        <div class="updates-section-title">LitteStar Beta 1.1</div>
        <div class="updates-section-time i18n" name="up_2_date">2020.05.11</div>
        <div class="updates-section-des">
          <ul class="updates-section-des-list">
            <li class="i18n" name="up_2_1">Bug fix</li>
            <li class="i18n" name="up_2_2">删除不必要的权限</li>
          </ul>
        </div>
      </section>
      <section class="updates-section updates-section_r">
        <div class="updates-section-title">LitteStar Beta 1.0</div>
        <div class="updates-section-time i18n" name="up_1_date">2020.05.08</div>
        <div class="updates-section-des">
          <ul class="updates-section-des-list">
            <li class="i18n" name="up_1_1">Stars同步功能</li>
            <li class="i18n" name="up_1_2">项目页面弹窗添加分类</li>
            <li class="i18n" name="up_1_3">自定义分类</li>
            <li class="i18n" name="up_1_4">分类分享</li>
          </ul>
        </div>
      </section>
    </div>
  </div>
  <div class="updates" v-else>
    <h2 class="updates-title">Updates</h2>
    <p class="updates-sub-title">Check out what's new at Little Star</p>
    <div class="updates-list">
      <section class="updates-section">
        <div class="updates-section-title">LitteStar 1.0</div>
        <div class="updates-section-time i18n" name="up_4_date">2021.07.01</div>
        <div class="updates-section-des">
          <ul class="updates-section-des-list">
            <li class="i18n" name="up_4_1">New index</li>
            <li class="i18n" name="up_4_2">Add new Github Trending Page</li>
            <li class="i18n" name="up_4_3">New price page</li>
            <li class="i18n" name="up_4_3">Change token scope and save strategy<a target="_blank" class="link" href="https://github.com/alianrock/LittleStar/wiki/Access-token-for-Little-Star">See details.</a></li>
            <li class="i18n" name="up_4_4">Bug fix</li>
          </ul>
        </div>
      </section>
      <section class="updates-section updates-section_r">
        <div class="updates-section-title">LitteStar Beta 3.11</div>
        <div class="updates-section-time i18n" name="up_5_date">01/22/2021</div>
        <div class="updates-section-des">
          <ul class="updates-section-des-list">
            <li class="i18n" name="up_5_1">Add Github Trending</li>
            <li class="i18n" name="up_5_2">Fix Star update bug</li>
          </ul>
        </div>
      </section>
      <section class="updates-section">
        <div class="updates-section-title">LitteStar Beta 3.0</div>
        <div class="updates-section-time i18n" name="up_4_date">10/20/2020</div>
        <div class="updates-section-des">
          <ul class="updates-section-des-list">
            <li class="i18n" name="up_4_1">Independent large screen page</li>
            <li class="i18n" name="up_4_2">Optimize data fetching speed </li>
            <li class="i18n" name="up_4_3">Email setting</li>
            <li class="i18n" name="up_4_4">Feedback</li>
          </ul>
        </div>
      </section>
      <section class="updates-section updates-section_r">
        <div class="updates-section-title">LitteStar Beta 2.0</div>
        <div class="updates-section-time i18n" name="up_3_date">06/01/2020</div>
        <div class="updates-section-des">
          <ul class="updates-section-des-list">
            <li class="i18n" name="up_3_1">Dark Mode theme</li>
            <li class="i18n" name="up_3_2">Settings Panel</li>
            <li class="i18n" name="up_3_3">Auto backup</li>
            <li class="i18n" name="up_3_4">Large Appereance</li>
          </ul>
        </div>
      </section>
      <section class="updates-section">
        <div class="updates-section-title">LitteStar Beta 1.1</div>
        <div class="updates-section-time i18n" name="up_2_date">05/11/2020</div>
        <div class="updates-section-des">
          <ul class="updates-section-des-list">
            <li class="i18n" name="up_2_1">Bug fix</li>
            <li class="i18n" name="up_2_2">Delete unnecessary permision</li>
          </ul>
        </div>
      </section>
      <section class="updates-section updates-section_r">
        <div class="updates-section-title">LitteStar Beta 1.0</div>
        <div class="updates-section-time i18n" name="up_1_date">05/08/2020</div>
        <div class="updates-section-des">
          <ul class="updates-section-des-list">
            <li class="i18n" name="up_1_1">Synchronize stars</li>
            <li class="i18n" name="up_1_2">Add custom categories</li>
            <li class="i18n" name="up_1_3">Editing window on Github page</li>
            <li class="i18n" name="up_1_4">Share categories</li>
          </ul>
        </div>
      </section>
    </div>
  </div>
    <!-- footer -->
  <!-- footer -->
  <Footer/>
</div>
</template>

<script>
// @ is an alias to /src
import Header from '@/components/Header'
import Footer from '@/components/Footer'

export default {
  components: {
    Header,
    Footer,
  },
  data() {
    return {
      isEn: true,
    }
  },
  watch: {
    lang() {
      this.isEn = this.lang.indexOf('en') !== -1
    },
  },
  mounted() {
    this.isEn = this.lang.indexOf('en') !== -1
  },
}
</script>
<style lang="scss" scoped>

.updates{
  margin: 100px 0;
  min-height: calc(100vh - 291px - 230px - 79px - 22px);
  animation: fadeUp 1.3s ease;
  .link{
    color: #48B2FF;
  }
}

@keyframes fadeUp{
  0% {
    opacity: 0;
    transform: translateY(50px);
  }
  100% {
    opacity: 1;
    transform: translateY(0px);
  }
}
.updates-list{
  position: relative;
  padding:0 10%;
}
.updates-list::before{
  display: block;
  content: '';
  height: 100%;
  width: 2px;
  background: #D8D8D8;
  position: absolute;
  left: 50%;
  top: 0;
  margin-left: -1px;
}
.updates-list::after{
  content: '';
  height: 12px;
  width: 12px;
  border-radius: 12px;
  position: absolute;
  background: #D8D8D8;
  left:50%;
  margin-left: -6px;
  bottom: 0;
}

.updates-title{
  font-size: 48px;
  color: #484852;
  line-height: 48px;
  font-weight: bold;
  text-align: center;
  margin-bottom: 8px;
}
.updates-sub-title{
  font-size: 14px;
  color: #C7C7C7;
  text-align: center;
  margin-bottom: 60px;
}
.updates-section{
  width: 50%;
  margin-right: 50%;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  padding-right: 34px;
  box-sizing: border-box;
  position: relative;
  margin-bottom: 30px;
  text-align: right;
}
.updates-section::before{
  content: '';
  height: 12px;
  width: 12px;
  border-radius: 12px;
  position: absolute;
  background: #D8D8D8;
  right: -6px;
  top: -1px;
}

.updates-section-title{
  font-size: 18px;
  color: #484852;
  margin-bottom: 4px;
}
.updates-section-des{
  font-size: 14px;
  color: #646574;
  margin-bottom: 4px;
}
.updates-section-des-list{
  /* list-style: none; */
  padding: 0;
  padding-right: 16px;
  direction: rtl;
  margin: 10px 0;
  li{
    list-style: disc;
  }
}
.updates-section-time{
  font-size: 12px;
  color: #C7C7C7;
}
.updates-section_r{
  margin-left: 50%;
  margin-right: 0;
  padding-right: 0;
  padding-left: 34px;
  align-items: flex-start;
  text-align: left;
  padding-right: 0;
}
.updates-section_r::before{
  right: auto;
  left: -6px;

}
.updates-section_r .updates-section-des-list{
  direction: ltr;
  padding-left: 16px;
}
</style>
